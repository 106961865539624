// @ts-nocheck

import React from 'react';

import Stepper from '@Src/components/ui/Stepper';
import i18n from '@Src/i18n';
import styles from './index.module.scss';

interface Props {
  transitionState?: string | undefined
  locale: string
  currentStepId: string
  pageType?: string
  advanceInqueryId: string
  children: any
}

const AdvanceInqueryLayout = ({ 
  transitionState,
  currentStepId = '',
  pageType = '',
  advanceInqueryId = '',
  locale = 'en-US',
  children,
  ...props
}: Props) => {
  const language = locale.split(/-/)[0]
  return (  
    <div className={styles.AdvanceInqueryLayout}>
      <div className={styles.advanceInqueryLayoutGrid}>
        <Stepper
          logotype={
            <img className={styles.logotype} src="/assets/svg/ALOADED-LOGOTYPE.svg" />
          }
          orientation="vertical"
          maxStep={advanceInqueryId?.length > 0 ? 5 : 1}
          currentStepId={currentStepId}
          baseUrl={`/inquery/${advanceInqueryId}`}
          steps={[
            {
              name: i18n.trans(language, 'funding-options'),
              url: `/funding`,
              id: 'funding'
            },
            {
              name: i18n.trans(language, 'distribution-reports'),
              url: `/statements`,
              id: 'statements'
            }
          ]}
        />
        <div className={styles.advanceInqueryLayoutStep}>
          {children}
        </div>
      </div>
    </div> 
  )
} 

export default AdvanceInqueryLayout