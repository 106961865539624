// @ts-nocheck

import React, { ReactElement } from 'react';
import styles from 
 './index.module.scss';

export interface Props {
	size?: number;
  margin?: any
}

export default function Loader({ size = 4, margin = null }: Props): ReactElement<Props> {
  // @ts-ignore
  return <div style={{ fontSize: size, marginLeft: margin, marginRight: margin, alignItems:'center' }} className={styles.loader}></div>;
}
