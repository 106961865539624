// @ts-nocheck

import React from 'react';
import Loader from '../Loader';

import styles from 
 './index.module.scss';

const LoadingPage = ({ }) => {
  return (
    <div className={styles.loadingPage}>
      <Loader />
    </div>
  )
}

export default LoadingPage