// @ts-ignore

import { AdvanceInquery } from "@Src/models/advanceInquery";
import { Lead } from "@Src/models/lead";
import { Pitch } from "@Src/models/pitch";
import { EventEmitter } from "events";
import { AdvanceInqueryResponseBody, ApplyCouponCodeResponseBody, CloudFunctionResponseBody, CreateLeadResponseBody, CreateStripePaymentIntentRequestBody, CreateStripePaymentIntentResponseBody, CreateUserByEmailResponseBody, CustomFormData, FindArtistByNameResponseBody, GetCouponResponseBody, GetNodesResponseBody, GetProductResponseBody, SubmitAdvanceInqueryResponseBody, SubmitFormResponseBody, SubmitLabelAdvanceInqueryResponseBody, SubmitPitchResponseBody, SubmitSyncRequestResponseBody } from "@Src/types/models";
import { LabelAdvanceInquery } from "@Src/models/labelAdvanceInquery";

export abstract class AloadedService extends EventEmitter {
    abstract createStripePaymentIntent(data: CreateStripePaymentIntentRequestBody) : Promise<CreateStripePaymentIntentResponseBody>;
    abstract callCloudFunction(functionName : string, ...parameters: Array<any>) : Promise<CloudFunctionResponseBody>;
    abstract createUserByEmail(email: string): Promise<CreateUserByEmailResponseBody>;
    abstract submitPitch(pitch : Pitch): Promise<SubmitPitchResponseBody>;
    abstract getCoupon(couponCode: string, productId: string): Promise<GetCouponResponseBody>;
    abstract getProduct(id: string): Promise<GetProductResponseBody>;
    abstract getNodes<T extends Node>(collectionName: string): Promise<GetNodesResponseBody>;
    abstract fetchAdvanceInquery(id: string): Promise<AdvanceInqueryResponseBody>;
    abstract submitSyncRequest(data: {}) : Promise<SubmitSyncRequestResponseBody>;
    abstract createLead(lead: Lead): Promise<CreateLeadResponseBody>;
    abstract submitForm(formId: string, value: CustomFormData) : Promise<SubmitFormResponseBody>;
    abstract submitAdvanceInquery(advanceInquery: AdvanceInquery): Promise<SubmitAdvanceInqueryResponseBody>;
    abstract submitLabelAdvanceInquery(labelAdvanceInquery: LabelAdvanceInquery): Promise<SubmitLabelAdvanceInqueryResponseBody>;
    abstract findArtistsByName(q: string): Promise<FindArtistByNameResponseBody>;
    abstract applyCouponCode(paymentIntentId: string, code: string, locale: string): Promise<ApplyCouponCodeResponseBody>;   
    abstract submitRightsForEstimate(advanceEstimateId: string, rightsValues: Record<string, number>): Promise<any>;
    abstract submitAdvanceInqueryForReview(advanceInqueryId: string, value: Record<string, any>) : Promise<any>;
    abstract uploadFiles(files: Array<File>, onUpdate : any) : Promise<any>
    abstract login(username: string, password: string) : Promise<any>;
    abstract loginWithProvider(providerId: string) : Promise<any>;
    abstract submitAdvanceInqueryFunding(advanceInqueryId: string, value: Record<string, any>) : Promise<any>;
    abstract submitMerchInterest(name: string, email: string) : Promise<any>;
}