import { createContext } from "react";

interface AppContextProps {
  setMobile: (value: boolean) => void
  isMobile: boolean
}

export const AppContext = createContext<AppContextProps>({
  setMobile: (value) => {},
  isMobile: false
})