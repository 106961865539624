import React, { ReactElement } from 'react';

import { Router } from '@reach/router'

import AdvanceInqueryFundingPage from '../../templates/advance/inquery/funding';
import AdvanceInqueryStatementsPage from '../../templates/advance/inquery/statements';
import AdvanceInqueryRightsPage from '../../templates/advance/inquery/rights';
import AdvanceInquerySubmittedPage from '../../templates/advance/inquery/submitted';

const DashboardPage = (): ReactElement => {
  // @ts-ignore
	return ( 
    <Router basepath="/">
      {/* @ts-ignore */}
      <AdvanceInqueryRightsPage path="inquery/:advanceInqueryId/rights" />
      {/* @ts-ignore */}
      <AdvanceInqueryFundingPage path="inquery/:advanceInqueryId/funding" />
      {/* @ts-ignore */}
      <AdvanceInqueryStatementsPage path="inquery/:advanceInqueryId/statements" />
      {/* @ts-ignore */}
      <AdvanceInquerySubmittedPage path="inquery/:advanceInqueryId/submitted" />
   </Router>
	);
};

export default DashboardPage; 
