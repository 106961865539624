// @ts-nocheck

import React, { useState } from 'react';
import cx from 'classnames';
import Text from '@Src/components/Text';
import styles from 
 './index.module.scss';

interface SliderProps {
  defaultValue: number;
  min: number;
  minLabel?: string|null;
  maxLabel?: string|null;
  max: number;
  showValue?: boolean;
  onValueChanged: (n: number) => void,
  theme: string
  renderValue?: (val: any) => any
}

export default function Slider({
  defaultValue = 0,
  min = 0,
  max = 1,
  minLabel = null,
  maxLabel = null,
  showValue = true,
  renderValue = (value) => value,
  onValueChanged = (value) => {},
  theme
} : SliderProps) {
  const [value, setValue] = useState(defaultValue)
  const valueChanged = (value : number) => {
    setValue(value);
    if (onValueChanged instanceof Function) {
      onValueChanged(value)
    }
  }
  let classNames = [styles.slider]
  if (theme === 'night' || theme === 'dark') {
      classNames.push(styles.sliderDark)
  }
  if (minLabel && maxLabel) {
    return (
      <div style={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'stretch', gap: 10}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
          <Text theme={theme} htmlText={minLabel} />
          <input 
            className={cx(classNames)}
            type="range"
            style={{flex: 1}}
            min={min}
            max={max}
            defaultValue={value}
            onChange={(e) => valueChanged(parseFloat(e.target.value))}
          />
          <Text theme={theme} htmlText={maxLabel} />
        </div>
        {showValue &&
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, flexDirection: 'row'}}>
          <Text theme={theme} htmlText={renderValue(value)} />
        </div>
        }
      </div>
    )
  }
  return (
    <input 
      className={cx(classNames)}
      type="range" 
      min={min}
      max={max}
      defaultValue={value}
      onChange={(e) => valueChanged(parseFloat(e.target.value))}
    />
  )
}