import { capitalize } from 'lodash';

var sprintf = require('sprintf-js').sprintf;
const sv = require('./lang/sv.json');
const en = require('./lang/en.json');

const languages : Record<string, any> = {
  sv,
  en
}

class I18n {
  trans(lang: string, str: string, ...params: any) {
    if (!lang) {
      lang = 'en'
    }
    let currentLanguage = languages[lang]
    var outputStr = str
    
    if (currentLanguage && currentLanguage[str]) {
      outputStr = currentLanguage[str]
    } 
    try {
      outputStr = sprintf(outputStr ?? "", ...params)
      
      outputStr = capitalize(outputStr)
      return outputStr
    } catch (e) {
      console.error(e)
      console.log(params)
    }
  }
}

export default new I18n()