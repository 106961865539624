// @ts-nocheck

import React, { ReactElement, useEffect, useState } from 'react';
import AdvanceInqueryFundingView from '@Src/components/Advance/Inquery/Funding';
import AdvanceInqueryLayout from '@Src/components/Advance/Inquery/Layout';

export interface Props {
    locale?: string
    path?: string
    advanceInqueryId: string
}

const AdvanceInqueryFundingPage = ({advanceInqueryId }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = ''
    let locale = 'en-US'
    const [content, setContent] = useState(null)
    const language = locale.split(/-/)[0]
   
    const [value, setValue] = useState({})
    return (
      <AdvanceInqueryLayout
        pageType={'signup'}
        transitionState={''}
        currentStepId={"funding"}
        locale={locale}
        advanceInqueryId={advanceInqueryId}
      >
        <AdvanceInqueryFundingView
          advanceInqueryId={advanceInqueryId}
          locale={locale}
        />
      </AdvanceInqueryLayout>
    );
};

export default AdvanceInqueryFundingPage;