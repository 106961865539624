import React, { ReactElement, useState } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import AdvanceInqueryStatementsView from '@Src/components/Advance/Inquery/Statements';
import AdvanceInqueryLayout from '@Src/components/Advance/Inquery/Layout';

export interface Props extends IPageProps {
    data: {
        contentfulPageTypePitch: IPageData;
    };
    locale?: string
    path?: string
    advanceInqueryId: string
}

const AdvanceInqueryStatementsPage = ({ data, advanceInqueryId, location, pageContext, transitionStatus }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = '', seoSettings = 'label pitch'
    let locale =  'en-US'
  
    const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
    const [content, setContent] = useState(null)
    const language = locale.split(/-/)[0]


    return (
      <AdvanceInqueryLayout
        currentStepId={"statements"}
        locale={locale}
        advanceInqueryId={advanceInqueryId}
      >
        <AdvanceInqueryStatementsView
          advanceInqueryId={advanceInqueryId}
          locale={locale}
        />
      </AdvanceInqueryLayout>
    );
};

export default AdvanceInqueryStatementsPage;