// @ts-nocheck

import React, { ReactElement, ReactNode } from 'react';

import cx from 'classnames';
import styles from './index.module.scss';

export interface Props {
	/**
	 * Text Heading Large - Will output h1
	 */
	heading01?: string;
	/**
	 * Text Heading Medium - Will output h2
	 */
	heading02?: string;
	/**
	 * Text Heading Small - Will output h3
	 */
	heading03?: string;
	/**
	 * Small Heading - Will output h4, uppercase
	 */
	uiHeading01?: string;
	/**
	 * Small Heading - Will output h5, uppercase
	 */
	uiHeading02?: string;
	/**
	 * Body
	 */
	body?: string;
	/**
	 * Display an pre formatted html text (from CMS with Rich Text Editor)
	 */
	htmlText?: string;
	/**
	 * Theme
	 */
	theme?: string;
	/**
	 * Custom className
	 */
	className?: string;

  striped?: boolean;
}

export interface TextProp {
	text?: string;
	theme?: string;
	children?: ReactElement | string | Array<ReactElement> | ReactNode;
	className?: string;
}

export const Heading01 = ({ text = '', theme, className = '', children }: TextProp): ReactElement => (
	<h1 className={cx(styles.textHeading, styles.large, theme && styles[theme], className)}>
		{text}
		{children}
	</h1>
);
export const Heading02 = ({ text = '', theme, className = '', children }: TextProp): ReactElement => (
	<h2 className={cx(styles.textHeading, styles.medium, theme && styles[theme], className)}>
		{text}
		{children}
	</h2>
);
export const Heading03 = ({ text = '', theme, className = '', children }: TextProp): ReactElement => (
	<h3 className={cx(styles.textHeading, styles.small, theme && styles[theme], className)}>
		{text}
		{children}
	</h3>
);

export const UiHeading01 = ({ text = '', theme, className = '', children }: TextProp): ReactElement => (
	<h4 className={cx(styles.uiHeading, styles.medium, theme && styles[theme], className)}>
		{text}
		{children}
	</h4>
);

export const UiHeading02 = ({ text = '', theme, className = '', children }: TextProp): ReactElement => (
	<h5 className={cx(styles.uiHeading, styles.small, theme && styles[theme], className)}>
		{text}
		{children}
	</h5>
);

export const Body = ({ text = '', theme, className = '', children }: TextProp): ReactElement => (
	<p className={cx(styles.body, theme && styles[theme], className)}>
		{text}
		{children}
	</p>
);

export const Bold = ({ text = '', className = '', children }: TextProp): ReactElement => (
	<strong className={cx(styles.body, styles.bold, className)}>
		{text}
		{children}
	</strong>
);
export const Italic = ({ text = '', className = '', children }: TextProp): ReactElement => (
	<em className={cx(styles.italic, styles.italic, className)}>
		{text}
		{children}
	</em>
);

export const HTMLText = ({ text = '', theme, className = '' }: TextProp): ReactElement => (
	/* eslint-disable react/no-danger */
	<div className={cx(styles.body, theme && styles[theme], className)} dangerouslySetInnerHTML={{ __html: text }} />
);

/* LAYOUTS */

export const TextLayout = ({ text = '', theme, children, className = '' }: TextProp): ReactElement => (
	<div className={cx(styles.layout, theme && styles[theme], className)}>
		{text}
		{children}
	</div>
);

const Text = ({
	heading01,
	heading02,
	heading03,
	uiHeading01,
	uiHeading02,
	body,
	htmlText,
  striped,
	theme,
	className
}: Props): ReactElement => {
  let classNames = cx([className, striped && 'striped'])

  return (
    <>
      {/* eslint-disable react/no-danger */}
      {htmlText && <HTMLText text={htmlText} theme={theme} className={classNames} />}
      {heading01 && <Heading01 text={heading01} theme={theme} className={classNames} />}
      {heading02 && <Heading02 text={heading02} theme={theme} className={classNames} />}
      {heading03 && <Heading03 text={heading03} theme={theme} className={classNames} />}
      {uiHeading01 && <UiHeading01 text={uiHeading01} theme={theme} className={classNames} />}
      {uiHeading02 && <UiHeading02 text={uiHeading02} theme={theme} className={classNames} />}
      {body && <Body text={body} theme={theme} className={classNames} />}
    </>
  )
};

export default Text;
