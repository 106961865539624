// @ts-nocheck

import React, { useEffect } from 'react';
import Text from '@Src/components/Text';

interface AdvanceInquerySubmittedViewProps {
  language?: string
}

const AdvanceInquerySubmittedView = ({
  language = 'en'
}: AdvanceInquerySubmittedViewProps) => {
  const mode = 'light'
  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
      window.dataLayer.push({
        type: 'event',
        event: 'thank_you_page'
      })
    }
  })
  return (
    <div style={{textAlign: 'center', padding: 20}}>
      <Text theme={mode} heading01={`Congratulations!`} />
      <Text theme={mode} htmlText={`You have now received an email with the offer in detail.`} />
    </div>
  )
}

export default AdvanceInquerySubmittedView