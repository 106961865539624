// @ts-nocheck

import React from 'react';

import styles from 
 './index.module.scss';

interface Props {
  onClick: (event: any) => void;
  children: any; 
}

export const TextButton = ({
  onClick,
  children
}: Props) => {
  return (
    <button onClick={onClick} className={styles.textButton}>{children}</button>
  )
}