import React, { useEffect, useState } from 'react';

import {Helmet} from "react-helmet";

import './App.scss';

import { AppContext} from './contexts/AppContext'

import DashboardPage from './pages/dashboard/[...]';

function App() {
  const [isMobile, setMobile] = useState<boolean>(false)
  useEffect(() => {
    const windowResizeListener = () => {
      if (typeof window !== 'undefined') {
        setMobile(window.matchMedia('screen and (max-width: 720pt)').matches)
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', windowResizeListener)
      windowResizeListener()
    }
    return () => {
      window.removeEventListener('resize', windowResizeListener)
    }
  }, [])
  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <AppContext.Provider value={{isMobile, setMobile}}>
        <DashboardPage />
      </AppContext.Provider>
    </div>
  );
}

export default App;
