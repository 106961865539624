import React, { ReactElement } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import AdvanceInquerySubmittedView from '@Src/components/Advance/Inquery/Submitted';
import AdvanceInqueryLayout from '@Src/components/Advance/Inquery/Layout';

export interface Props extends IPageProps {
    data: {
        contentfulPageTypePitch: IPageData;
    };
    locale?: string
    path?: string
    advanceInqueryId: string
}

const AdvanceInquerySubmittedPage = ({ data, advanceInqueryId, location, pageContext, transitionStatus }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = '', seoSettings = 'label pitch'
    let locale =  'en-US'
  
    const language = locale.split(/-/)[0]

    return (
      <AdvanceInqueryLayout
        currentStepId={"submited"}
        locale={locale}
        advanceInqueryId={advanceInqueryId}
      >
        <AdvanceInquerySubmittedView
        />
      </AdvanceInqueryLayout>
    );
};

export default AdvanceInquerySubmittedPage;