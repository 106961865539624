// @ts-nocheck

import React, { useState } from 'react';

import cx from 'classnames';

import styles from 
 './index.module.scss';
import Link from '@UI/Link';
import Text from '@Src/components/Text';
import { List } from 'phosphor-react';

export interface Step {
  name: string;
  url: string;
  id: string;
}

interface StepperStepProps {
  label: string
  url: string
  disabled: boolean
  isActive: boolean
  number: number
}

const StepperStep = ({ number, disabled = false, label, url, isActive }: StepperStepProps) => {
  const mode = 'light'
  const LinkComponent = !disabled ? Link : "div"
  return (
    <LinkComponent
      id={url}
      url={url}
      style={{opacity: disabled ? 0.5 :1}}
      className={cx(styles.stepperStep, isActive && styles.stepperStepActive)}
    >
      <div className={styles.stepperStepLabel}>
        <p style={{textDecoration: 'none'}}>{label}</p>
      </div>
      <div className={styles.stepperStepUnderline}>&nbsp;</div>
    </LinkComponent>
  )
}

interface Props {
  steps: Array<Step>
  currentStepId: string | null
  children?: any
  maxStep: number
  orientation: string
  baseUrl: string
  logotype: any
}

export const Stepper = ({
  steps,
  baseUrl = "",
  currentStepId=null,
  children,
  logotype,
  orientation = 'horizontal',
  maxStep = 1,
  ...props
}: Props) => {
  const [isOpen, setOpen] = useState(false) 
  let classNames = [styles.steper]
  if (orientation === 'vertical') {
    classNames.push(styles.stepperVertical)
  }
  return (
    <div className={cx(classNames)} {...props}>
      <div className={styles.stepperHeader}>
        <div style={{ flex: 1}}>
          {logotype}
        </div>
        <button onClick={() => setOpen(!isOpen)} style={{border: 'none', background: 'none'}}>
          <List size={18} />
        </button>
      </div>
      <div className={cx(styles.stepperMenu, isOpen && styles.stepperMenuOpen)}>
      {steps.map(
        (step, i) => (
          <React.Fragment key={step.id}>
          {i > 0 && orientation !== 'vertical' &&
          <div className={styles.stepperStep}>
            <Text htmlText={`&rarr;`} />  

            <div className={styles.stepperStepUnderline}>&nbsp;</div>
          </div>
          }
          <StepperStep
            key={step.id}
            number={i + 1}
            disabled={i > maxStep - 1}
            label={step.name}
            url={`${baseUrl}${step.url}`}
            isActive={currentStepId === step.id}
          />
          </React.Fragment>
        )
      )}
      </div>
    </div>
  )
}
export default Stepper;