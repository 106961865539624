// @ts-nocheck

import { uploadFiles } from '@Src/actions/aloaded';
import i18n from '@Src/i18n';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { SubmitButton } from '../ui/SubmitButton';
import { TextButton } from '../ui/TextButton';
import styles from './index.module.scss';

interface Props {
  onFilesUploaded: (files: Array<any>) => void
  language: string
  defaultUploadedFiles: Array<any>
  accept?: any
}

const Dropzoned = ({
  onFilesUploaded,
  language = 'en',
  defaultUploadedFiles = [],
  accept
}: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>(defaultUploadedFiles) 
  const [uploadTasks, setUploadTasks] = useState<Array<any>>([])
  const [isDraggingOver, setDraggingOver] = useState<boolean>(false)
  const onDragEnter = (event: any) => {
    setDraggingOver(true)
  }
  const onDragLeave = (event: any) => {
    setDraggingOver(false)
  }
  const handleUploadUpdate = ({ uploadTasks, finishedUploads } : any) => {
    setUploadedFiles(Object.values(finishedUploads))
    setUploadTasks(Object.values(uploadTasks))
  }
  return (
    <Dropzone accept={accept} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={(acceptedFiles) => {
      uploadFiles(acceptedFiles, handleUploadUpdate).then(
        (results) => {
          handleUploadUpdate(results)
          onFilesUploaded(Object.values(results.finishedUploads))
        }
      )
      setDraggingOver(false)
    }}>
      {({getRootProps, getInputProps, open}) => (
        <section className={styles.dropzone}>
          <div {...getRootProps()}>
            <input {...getInputProps()} /> 
            {isDraggingOver ?
              <h3>{i18n.trans(language, 'drop-files-here')}</h3>
            :
            <>
              <SubmitButton
                buttonType="button"
                label={i18n.trans(language, 'select-files')}
                primary={true}
                rounded={true}
                onClick={open}
              /><br />
              <p>{i18n.trans(language, 'accepted-file-types', Object.values(accept ?? []).flat().map(s => s))}</p>
            </>
          }
          {uploadedFiles?.length > 0 &&
          <>
            <table>
              <tbody>
                {uploadedFiles.map((file : any) => {
                  return (
                    <tr key={file.url}>
                      <td></td>
                      <td>
                        {file.name}
                      </td>
                      <td>
                        <TextButton onClick={() => {
                          const newUploadedFiles = uploadedFiles
                          newUploadedFiles.splice(newUploadedFiles.indexOf(file), 1)
                          setUploadedFiles(newUploadedFiles)
                        }}>{i18n.trans(language, 'remove')}</TextButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
          }
          </div>
        </section>
      )}
    </Dropzone>
  )
}
export default Dropzoned