// @ts-nocheck

import { fetchAdvanceInquery, submitAdvanceInqueryRights } from '@Src/actions/aloaded';
import Slider from '@Src/components/ui/Slider';
import React, { useEffect, useState } from 'react';
import Text from '@Src/components/Text';
import i18n from '@Src/i18n';
import styles from './index.module.scss';
import { navigate } from '@reach/router';
import { SubmitButton } from '@Src/components/ui/SubmitButton';
import LoadingPage from '@Src/components/ui/LoadingPage';

interface Props { 
  locale: string
  advanceInqueryId: string
}

const AdvanceInqueryRightsView = ({
  locale = 'en-US',
  advanceInqueryId
}: Props) => {
  const mode = 'light'
  const language = locale.split(/-/)[0]
  const [advanceInquery, setAdvanceInquery] = useState<any>(null)
  const [submit, setSubmit] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [ rightsValues, setRightsValues ] = useState<Record<string, number>>({})
  useEffect(() => {
    setLoading(true)
    fetchAdvanceInquery(advanceInqueryId).then((advanceInqueryResult) => {
      setAdvanceInquery(advanceInqueryResult)
      setRightsValues(advanceInqueryResult.rightsValues ?? {})
      setLoading(false)
    }).catch(e => {
      console.error(e)
      setLoading(false)
    })
    
  }, [])
  const setArtistRights = (value: number) => {
    let newRightsValues : Record<string, number> = {}
    Object.keys(rightsValues).forEach(key => {
      newRightsValues[key] = value 
    })
    advanceInquery.releases.map((release: any) => {
      newRightsValues[release.uri] = value
    })
    newRightsValues["artist"] = value
    setRightsValues(newRightsValues)
  }
  const setAllRightsValues = (value : number) => {
    let newRightsValues : Record<string, number> = {}
    Object.keys(rightsValues).forEach(key => {
      newRightsValues[key] = value 
    })
    advanceInquery.releases.map((release: any) => {
      newRightsValues[release.uri] = value
    })
    newRightsValues["all"] = value
    setRightsValues(newRightsValues)
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (advanceInquery?.id) {
      setSubmit(true)
      submitAdvanceInqueryRights(advanceInquery.id, rightsValues).then((result) => {
        if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
          window.dataLayer.push({
            type: 'event',
            event: 'step_2_submit_rights_for_estimate'
          })
        }
        setSubmit(false)
        navigate(`/inquery/${advanceInquery.id}/funding`)        
      })
    }
    return false
  }
  if (isLoading) {
    return (
      <LoadingPage />
    )
  }
  if (advanceInquery) {
    return ( 
      <form className={styles.advanceFundingView} style={{opacity: isLoading || submit ? 0.5 : 1, height: '100vh', margin: 0, padding: 0}} method="POST" onSubmit={handleSubmit}>
        <div className={styles.advanceFundingViewForm} style={{padding: 50, flex: "1", overflow: 'scroll'}}>
          <Text theme={mode} body={i18n.trans(language, 'help-us-get-a-better-idea-of-your-song-ownership')} />
          <table className={styles.advanceFundingTable} style={{width: '100%'}}>
            <thead>
              <tr>
                <th></th> 
                <th><Text theme={mode} htmlText={i18n.trans(language, 'name')} /></th>
                <th><div style={{display: 'none'}}><Text theme={mode} htmlText={i18n.trans(language, 'streams-last-month')} /></div></th>
                <th><Text theme={mode} htmlText={i18n.trans(language, 'rights')} /></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img style={{width: '50pt'}} />
                </td>
                <td>
                  <Text theme={mode} htmlText={i18n.trans(language, 'artist')} />
                </td>
                <td>

                </td>
                <td colSpan={2}>
                  <Slider
                    minLabel={'0%'}
                    maxLabel={'100%'}
                    min={0}
                    max={100}
                    defaultValue={rightsValues?.artist ?? 100}
                    onValueChanged={(value : number) => setArtistRights(value)}
                    theme={mode}
                    showValue={true}
                    renderValue={(value) => i18n.trans(language, 'x-percent', value)}
                  />
                </td>
              </tr>
              <tr style={{display: 'none'}}>
                <td>
                  <img  style={{width: '50pt'}} />
                </td>
                <td>
                  <Text theme={mode} htmlText={i18n.trans(language, 'all-masters')}  />
                </td>
                <td></td>
                <td colSpan={2}>
                  <Slider
                    minLabel={'0%'}
                    maxLabel={'100%'}
                    min={0}
                    max={100}
                    defaultValue={rightsValues?.all}
                    onValueChanged={(value : number) => setAllRightsValues(value)}
                    showValue={true}
                    renderValue={(value) => i18n.trans('x-percent', value)}
                    theme={mode}
                  />
                </td>
              </tr>
              {advanceInquery?.releases?.map((release : any) => (
                <React.Fragment key={release.id}>
                  <tr>
                    <td>
                      <img src={release.imageUrl || (release.coverArt.sources || release.images)[0]?.url} style={{width: '50pt'}} />
                    </td>
                    <td>
                      <Text theme={mode} htmlText={release.name}  />
                    </td> 
                    <td></td>
                    <td colSpan={2} key={rightsValues[release.uri]}>
                      {/*<Slider
                        minLabel={'0%'}
                        maxLabel={'100 %'}
                        min={0}
                        max={100}
                        defaultValue={rightsValues[release.uri] ?? 100}
                        onValueChanged={(value : number) => {
                          setRightsValues({
                            ...rightsValues,
                            [release.uri]: value
                          })
                        }}
                        theme={mode}
                        showValue={true}
                        renderValue={(value) => i18n.trans('n-percent', value)}
                      />*/}
                    </td>
                  </tr> 
                  {release.tracks?.items?.map(track => (
                    <tr key={track.uri}>
                      <td>
                        <img src={release.imageUrl || (release.coverArt.sources || release.images)[0]?.url} style={{width: '50pt'}} />
                      </td>
                      <td>
                        <Text theme={mode} htmlText={track.name}  />
                      </td> 
                      <td></td>
                      <td colSpan={2} key={release.uri + ':' + rightsValues[track.uri]}>
                        {/*<Slider
                          minLabel={'0%'}
                          maxLabel={'100 %'}
                          min={0}
                          max={100}
                          defaultValue={rightsValues[track.uri] ?? 100}
                          onValueChanged={(value : number) => {
                            setRightsValues({
                              ...rightsValues,
                              [track.uri]: value
                            })
                          }}
                          theme={mode}
                          showValue={true}
                          renderValue={(value) => i18n.trans(language, 'x-percent', value)}
                        />*/}
                      </td>
                    </tr> 
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{flex: '0 0 auto', padding: 20}} className={styles.advanceFundingViewFooter}>
          <div style={{textAlign: 'center', flex: 1}}>
            {/*<h3 style={{fontWeight: 1, fontSize: 30}}>{numeral(advanceInquery.totalStreams || 0).format('0.0a')}</h3>
            <p style={{fontSize: 18}}>{i18n.trans(language, 'total-streams-last-month')}</p>*/}
          </div>
          <div>
            <SubmitButton
              rounded={true}
              primary={true}
              isSubmitting={submit}
              label={i18n.trans(language, submit ? '' : 'confirm')}
            />
          </div>
        </div>
      </form>
    )
  } else {
    return <div></div>
  }
}
export default AdvanceInqueryRightsView