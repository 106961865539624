// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import numeral from 'numeral';
import cx from 'classnames';

import { fetchAdvanceInquery, submitAdvanceInqueryForReview, submitAdvanceInqueryFunding } from '../../../../actions/aloaded';
import Text from '../../../../components/Text';
import i18n from '../../../../i18n';
import { navigate } from '@reach/router';
import { SubmitButton } from '../../../../components/ui/SubmitButton';
import styles from './index.module.scss';
import LoadingPage from '../../../../components/ui/LoadingPage';
import Loader from '../../../../components/ui/Loader';
import { ButtonGroup } from '../../../../components/ui/TabBar';
import { ButtonGroupButton } from '../../../../components/ui/ButtonGroup/ButtonGroupButton';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import { AppContext } from '@Src/contexts/AppContext';
interface Props {
  locale: string
  advanceInqueryId: string
}


const AdvanceInqueryFundingView = ({
  locale = 'en-US',
  advanceInqueryId
}: Props) => {
  const currency = 'SEK'
  const mode = 'light'
  const language = locale.split(/-/g)[0] 

  const { isMobile } = useContext<AppContextProps>(AppContext)

  const [rightsValues, setRightsValues] = useState<Record<string, number>>({})
  const [advanceInquery, setAdvanceInquery] = useState<any>(null)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isError, setError] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)
  const [contractLengthInYears, setContractLengthInYears] = useState(3)
  const [isCalculating, setCalculating] = useState(false)
  const [interestedInBuyout, setInterestedInBuyout] = useState<boolean>(false)
 


  useEffect(() => {
    setLoading(true)
    fetchAdvanceInquery(advanceInqueryId).then((advanceInqueryResult) => {
      setAdvanceInquery(advanceInqueryResult)
      setContractLengthInYears(advanceInqueryResult?.contractLengthInYears)
      setInterestedInBuyout(advanceInqueryResult?.interestedInBuyout)
      setLoading(false)
    }).catch(e => {
      console.error(e)
      setLoading(false)
      setError(true)
    })
    
  }, [])

  const [isSummaryExpanded, setSummaryExpanded] = useState(false)

  const handleSubmit = (event : any) => {
    event.preventDefault()
    setSubmit(true)
    if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
      window.dataLayer.push({
        type: 'event',
        event: 'step_3_submit_advance_inquery_funding'
      })
    }
    if (isMobile) {
      submitAdvanceInqueryForReview(advanceInqueryId, {
        ableToMoveCatalogue: true,
        files: [],
        interestedInBuyout,
        distributorName: ''
      }).then(result => {
        navigate(`/inquery/${advanceInqueryId}/submitted`)
      })
      return
    }
    submitAdvanceInqueryFunding(
      advanceInqueryId,
      {
        contractLengthInYears,
        interestedInBuyout
      }
    ).then((result) => {
      setSubmit(false)
      navigate(`/inquery/${advanceInqueryId}/statements`)
    }).catch(err => {
      setSubmit(false)
    })
    return false
  }
  if (isLoading) {
    return (
      <LoadingPage /> 
    )
  }
  const handleToggleExpandSummary = (event: any) => { 
    event.preventDefault() 
    setSummaryExpanded(!isSummaryExpanded)
    return false
  }
  if (advanceInquery) {
    return ( 
      /* @ts-ignore */
      <form className={styles.advanceFundingView} style={{opacity: submit ? 0.5 : 1, margin: 0, padding: 0}} method="POST" onSubmit={handleSubmit}>
        <div style={{padding: 50, flex: 1}}>
          <h3 style={{marginBottom: 50}}>{i18n.trans(language, interestedInBuyout ? 'you-are-eligible-for-a-buyout-of' : 'you-are-eligible-for-an-advance-of', `${numeral(interestedInBuyout ? (advanceInquery?.buyout ?? 0) : (advanceInquery?.advanceByYears[contractLengthInYears] ?? 0)).format('0,0')} ${advanceInquery?.currency?.toUpperCase()}`)}</h3>
          {false && <Text theme={mode} htmlText={i18n.trans(language, 'customize-advance.text')} />}
          <Text theme={mode} htmlText={`Choose funding option below and submit. We will be in touch as soon as possible.`} />
          <ButtonGroup theme={mode}>
            <ButtonGroupButton 
              theme={mode}
              active={!interestedInBuyout && contractLengthInYears === 3}
              onClick={() => {
                setContractLengthInYears(3)
                setInterestedInBuyout(false)
              }}
            >
              {i18n.trans(language, 'x-year-distribution-deal', 3)}
            </ButtonGroupButton>
            <ButtonGroupButton 
              theme={mode}
              active={!interestedInBuyout && contractLengthInYears === 5}
              onClick={() => {
                setContractLengthInYears(5)
                setInterestedInBuyout(false)
              }}
            >
              {i18n.trans(language, 'x-year-distribution-deal', 5)}
            </ButtonGroupButton>
            <ButtonGroupButton 
              theme={mode}
              active={interestedInBuyout}
              onClick={() => setInterestedInBuyout(true)}
            >
              {i18n.trans(language, 'full-buyout')}
            </ButtonGroupButton>
          </ButtonGroup>
          <br />
          {/*<Text theme={mode} heading03={i18n.trans(language, 'artist-income')} />*/}
          {/* @ts-ignore */}
          {/*<div className={styles.royaltyPercents}>
            <div>
              <h3>100%</h3>
              <span>{i18n.trans(language, 'before')}</span>
            </div>
            <div>
              <h3>80%</h3>
              <span>{i18n.trans(language, 'if-recouped-before')}</span>
            </div>
            <div>
              <h3>100%</h3>
              <span>{i18n.trans(language, 'at-the-end-of-deal')}</span>
            </div>
          </div>*/}
          <div className={styles.advanceInquerySubmitDesktop}>
          <SubmitButton
            rounded={true}
            primary={true}
            isSubmitting={submit}
            label={i18n.trans(language, submit ? '' : 'continue-to-upload-reports')}
          />
          </div>
          <div className={styles.advanceInqueryLayoutMobileGutter} />
        </div>
        <div className={cx(styles.advanceInqueryLayoutSummary, isSummaryExpanded && styles.advanceInqueryLayoutSummaryExpanded)}>
          <button onClick={handleToggleExpandSummary} className={styles.advanceInquerySummaryDrawerHandle}>
            {isSummaryExpanded ? <ArrowDown size={23} /> : <ArrowUp size={23} />}
          </button>
          {advanceInquery &&
            <>
              <div className={styles.advanceInquerySummarySimple}>
                <div style={{flex: 1}}>
                  <Text htmlText={`Estimated total`} theme="night" />
                </div>
                <div>
                  {isCalculating ?
                  <Loader />
                  : numeral(interestedInBuyout ? (advanceInquery?.buyout ?? 0) : (advanceInquery?.advanceByYears[contractLengthInYears] ?? 0)).format('0,0')} {advanceInquery?.currency?.toUpperCase()}
                </div>
              </div>
              <div className={styles.advanceInquerySummaryDetailed}>
                <div style={{padding: 20}}>
                  <h6>{i18n.trans(language, 'initial-funding-estimate')}</h6>
                </div>
                <table className={styles.advanceInquerySummaryTable}>
                  <tbody>
                    <tr>
                      <td>{i18n.trans(language, 'advance')}</td>
                      <td className={styles.amount}>
                        {isCalculating ?
                          <Loader />
                          : numeral(interestedInBuyout ? (advanceInquery?.buyout ?? 0) : (advanceInquery?.advanceByYears[contractLengthInYears] ?? 0)).format('0,0')} {advanceInquery?.currency?.toUpperCase()}
                      </td>
                    </tr>
                  {!interestedInBuyout && <tr>
                      <td>
                        {i18n.trans(language, 'recoupment-rate')}
                      </td>
                      <td className={styles.amount}>
                        {isCalculating ?
                          <Loader />
                          : `${numeral(advanceInquery?.recoupmentRate ?? 80).format('0,0')}%`}
                      </td>
                    </tr>}
                  </tbody>
                </table>
                <div style={{padding: '20pt'}}>
                  <p>{i18n.trans(language, 'many-artists-qualify-for-even-better-rates-see-how')}</p>
                </div>
              </div>
              <div style={{padding: 20}} className={styles.advanceInquerySubmitMobile}>
                <SubmitButton
                  rounded={true}
                  primary={true}
                  isSubmitting={submit}
                  label={i18n.trans(language, submit ? '' : isMobile ? 'submit' : 'continue-to-upload-reports')}
                />
              </div>
            </>
          }
        </div>
      </form>
    )
  } else {
    return (
      <div>
        <Text htmlText={i18n.trans(language, 'error')} />
      </div>
    )
  }
}

export default AdvanceInqueryFundingView