// @ts-ignore


import { AdvanceInquery } from "@Src/models/advanceInquery"
import { SubmitAdvanceInqueryResponseBody } from "@Src/types/models"
 
export class MoodlerAloadedService {
  async findArtistsByName(q: string) {
    return await fetch(`${process.env.REACT_APP_MOODLER_API_URL}/findArtistsByName?q=${encodeURIComponent(q)}`).then(r => r.json())
  }
  async calculateAdvanceInqueryFunding(advanceInqueryId: string, value: { contractLengthInYears: number, interestedInBuyout: boolean}) {
    const { contractLengthInYears, interestedInBuyout } = value
    return await this.callCloudFunction(
      'calculateAdvanceInqueryFunding',
      {
        advanceInqueryId,
        contractLengthInYears,
        interestedInBuyout
      }
    )
  }
  async callCloudFunction(functionName : string, parameters: Record<string, any>) : Promise<any> {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_MOODLER_API_URL}/${functionName}`,
        {
          method: 'POST',
          body: JSON.stringify({
            ...parameters
          })
        }
      )
      const data = await result.json()
      return data
    } catch (e) {
      throw e
    }
  }

  async submitAdvanceInqueryFunding(advanceInqueryId: string, value: Record<string, any>) : Promise<any> {
    const { contractLengthInYears, interestedInBuyout } = value
    return await this.callCloudFunction(
      'submitAdvanceInqueryFunding',
      {  
        advanceInqueryId,
        contractLengthInYears,
        interestedInBuyout
      }
    )
  }
  
  async submitAdvanceInqueryRights(advanceInqueryId: string, rightsValues: Record<string, number>) : Promise<any> {
    return await this.callCloudFunction(
      'submitAdvanceInqueryRights',
      {
        advanceInqueryId,
        rightsValues
      }
    )
  }

  async fetchAdvanceInquery(advanceInqueryId: string) {
    return await fetch(`${process.env.REACT_APP_MOODLER_API_URL}/getAdvanceInquery?id=${advanceInqueryId}`).then(r => r.json())
  }

  async submitAdvanceInqueryForReview(advanceInqueryId: string, value: Record<string, any>) : Promise<any> {
    const {
      ableToMoveCatalogue,
      distributorName,
      interestedInBuyout,
      files
    } = value
    return await this.callCloudFunction(
      'submitAdvanceInqueryForReview',
      { 
        advanceInqueryId,
        distributorName,
        ableToMoveCatalogue,
        interestedInBuyout,
        files
      }
    )
  }

  async submitAdvanceInquery(advanceInquery : AdvanceInquery) : Promise<SubmitAdvanceInqueryResponseBody>{
    return await this.callCloudFunction(
      "submitAdvanceInquery",
      {
        ...advanceInquery
      }
    )
  }
}