import React, { ReactElement, useState } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import AdvanceInqueryRightsView from '@Src/components/Advance/Inquery/Rights';
import AdvanceInqueryLayout from '@Src/components/Advance/Inquery/Layout';

export interface Props extends IPageProps {
    data: {
        contentfulPageTypePitch: IPageData;
    };
    locale?: string
    advanceInqueryId: string
    path?: string
}

const AdvanceInqueryRightsPage = ({ advanceInqueryId, data, location, transitionStatus }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = '', seoSettings = 'label pitch'
    let locale = 'en-US'
    const language = locale.split(/-/)[0]
    const [submit, setSubmit] = useState(false)
    const [value, setValue] = useState({ rightsValues: {}})
   
    return (
        <AdvanceInqueryLayout
          pageType="advanceInquery"
          currentStepId={"rights"}
          locale={locale}
          advanceInqueryId={advanceInqueryId}
        >
            <AdvanceInqueryRightsView
              advanceInqueryId={advanceInqueryId}
              locale={locale}
            />
        </AdvanceInqueryLayout>
    );
};

export default AdvanceInqueryRightsPage;