// @ts-nocheck

// Import the functions you need from the SDKs you need

import { initializeApp, FirebaseApp } from "firebase/app";

import { Firestore, getFirestore, getDocs, Timestamp, connectFirestoreEmulator, collection, QueryDocumentSnapshot } from "firebase/firestore"
import { Functions, getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions"
import { getAuth, connectAuthEmulator, createUserWithEmailAndPassword, Auth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getStorage, connectStorageEmulator, ref, uploadBytesResumable, getDownloadURL, FirebaseStorage } from "firebase/storage";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import { makeid } from "@Utils/randomString";

import {AloadedService} from "@Src/services/service";
import { Pitch } from "@Src/models/pitch";
import { Lead } from "@Src/models/lead";
import { AdvanceInquery } from "@Src/models/advanceInquery";
import { ApplyPromotioCodeResponseBody, Node, CloudFunctionResponseBody, CreateLeadResponseBody, CreateStripePaymentIntentRequestBody, CreateStripePaymentIntentResponseBody, CreateUserByEmailResponseBody, CustomFormData, GetCouponResponseBody, GetProductResponseBody, SubmitAdvanceInqueryResponseBody, SubmitFormResponseBody, SubmitPitchResponseBody, AdvanceInqueryResponseBody, FindArtistByNameResponseBody, SubmitLabelAdvanceInqueryResponseBody } from "@Src/types/models";
import { LabelAdvanceInquery } from "@Src/models/labelAdvanceInquery";

interface FirebaseConfig {
  appId: string |undefined;
  apiKey: string | undefined;
  authDomain: string | undefined;
  databaseURL: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  measurementId: string | undefined;
}

export let firebaseConfig : FirebaseConfig = {
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export class FirebaseAloadedService extends AloadedService {
  config : FirebaseConfig = firebaseConfig
  fApp : FirebaseApp | null = null
  db : Firestore | null = null
  functions : Functions
  auth : Auth
  appCheck: any
  storage: FirebaseStorage
  googleAuth: GoogleAuthProvider
  constructor(config : FirebaseConfig = firebaseConfig, name = 'default') {
    super()
    // Initialize Firebase
    this.config = config
    this.fApp = null
    this.fApp = initializeApp(this.config, name);
    this.db = getFirestore(this.fApp)
    this.storage = getStorage(this.fApp)
    this.googleAuth = new GoogleAuthProvider();
    //connectFirestoreEmulator(db, 'localhost', 8028);
    this.functions = getFunctions(this.fApp, 'europe-west1')
    this.auth = getAuth(this.fApp)
    if (process.env.REACT_APP_FIREBASE_RECAPTCHA_KEY) {
      // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
      // key is the counterpart to the secret key you set in the Firebase console.
      this.appCheck = initializeAppCheck(this.fApp, {
        provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_RECAPTCHA_KEY),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
      });
    }
    if (process.env.REACT_APP_NODE_ENV == 'development') {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      // @ts-ignore
      connectFirestoreEmulator(this.db, process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_HOST!, process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_PORT);
      // @ts-ignore
      connectFunctionsEmulator(this.functions, process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST!, process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_PORT);
      // @ts-ignore
      connectStorageEmulator(this.storage, process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST!, process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT)
    }
  }
  async createStripePaymentIntent(data: CreateStripePaymentIntentRequestBody) : Promise<CreateStripePaymentIntentResponseBody> {
    return await this.callCloudFunction("createStripePaymentIntent", data) as CreateStripePaymentIntentResponseBody
  }

  async submitSyncRequest(data: {}) {
    return await this.callCloudFunction('submitSyncRequest', { data })
  }

  async callCloudFunction(functionName : string, ...parameters: Array<any>) : Promise<any> {
    const cloudFunc = httpsCallable(this.functions, functionName);
    let result = await cloudFunc(...parameters);
    if (result?.result) return result?.result
    return result?.data
  }
  async submitForm(formId: string, value: CustomFormData) : Promise<SubmitFormResponseBody> {
    return await this.callCloudFunction(
      "submitForm",
      {
        formId,
        value
      }
    ) 
  }
  async createUserByEmail(email: string): Promise<CreateUserByEmailResponseBody> {
    const password = makeid(6);
    console.log(email)
    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      )
      return {
        email
      }
    } catch (e) {
      throw e
    }
  }

  async applyCouponCode(paymentIntentId: string, couponCode: string, locale: string) : Promise<ApplyPromotioCodeResponseBody> {
    return await this.callCloudFunction(
        "applyCouponCodeToPaymentIntent",
        {
          paymentIntentId,
          couponCode,
          locale
        }
    ) as ApplyPromotioCodeResponseBody
  }

  async submitPitch(pitch : Pitch): Promise<SubmitPitchResponseBody> {
    return await this.callCloudFunction(
        "submitPitch",
        {
          pitch
        }
    )
  }
  async submitAdvanceInquery(advanceInquery : AdvanceInquery) : Promise<SubmitAdvanceInqueryResponseBody>{
    return await this.callCloudFunction(
      "submitAdvanceInquery",
      {
        ...advanceInquery
      }
    )

  }
  async submitLabelAdvanceInquery(labelAdvanceInquery : LabelAdvanceInquery) : Promise<SubmitLabelAdvanceInqueryResponseBody>{
    const {
      name,
      labelName,
      estimatedMonthlyStreams,
      email
    } = labelAdvanceInquery
    if (!name) {
      throw "Invalid name"
    }
    if (!labelName) {
      throw "Invalid label name"
    }
    if (!email) {
      throw "Invalid e-mail address"
    }
    if (isNaN(estimatedMonthlyStreams)) { 
      throw "estimatedMonthlyStreams must be a postiive integer"
    }
    if (estimatedMonthlyStreams < 1) {
      throw "Estimated monthly streams must be equal or more than 1"
    }
    return await this.callCloudFunction(
      "submitLabelAdvanceInquery",
      {
        name,
        labelName,
        estimatedMonthlyStreams: estimatedMonthlyStreams,
        email
      }
    )
  }

  async createLead(lead: Lead): Promise<CreateLeadResponseBody> {
   const result = await this.callCloudFunction(
      "createLead",
      {
        ...lead
      }
    )
    return result as CreateLeadResponseBody
  }

  async findArtistsByName(q: string): Promise<FindArtistByNameResponseBody> {
    return  this.callCloudFunction(
      "findArtistsByName",
      {
        q
      }
    )
  }
  async submitMerchInterest(name: string, email: string) : Promise<any> {
    return await this.callCloudFunction(
      'submitMerchInterest',
      {
        name,
        email
      }
    )
  }
  async getNodes<T extends Node>(collectionName: string): Promise<Array<T>> {
    const q = collection(this.db!, collectionName)
    const querySnapshots = await getDocs(q)
    let nodes : Array<T> = []
    querySnapshots.forEach((doc : QueryDocumentSnapshot) => {
      // @ts-ignore
      let node : Node = {
        ...(doc.data() as Node),
        id: doc.id
      }
      Object.keys(node).map(k => {
        let val : any = node[k]
        if (val instanceof Date) {
          node[k] = Timestamp.fromDate(node[k] as any)
        }
      })
      return nodes.push(node as any);
    })
    return nodes
  }
  
  async getProduct(id: string): Promise<GetProductResponseBody> {
    return await this.callCloudFunction(
      "getProduct",
      {
        id
      }
    ) as GetProductResponseBody
  }

  async fetchAdvanceInquery(id: string): Promise<any> {
    return await this.callCloudFunction(
      "getAdvanceInquery",
      {
        id
      }
    )
  }

  async submitRightsForEstimate(advanceInqueryId: string, rightsValues: Record<string, number>) : Promise<any> {
    return await this.callCloudFunction(
      'submitRightsForEstimate',
      {
        advanceInqueryId,
        rightsValues
  
      }
    )
  }
  async submitAdvanceInqueryForReview(advanceInqueryId: string, value: Record<string, any>) : Promise<any> {
    const {
      ableToMoveCatalogue,
      distributorName,
      interestedInBuyout,
      files
    } = value
    return await this.callCloudFunction(
      'submitAdvanceInqueryForReview',
      { 
        advanceInqueryId,
        distributorName,
        ableToMoveCatalogue,
        interestedInBuyout,
        files
      }
    )
  }

  async uploadFiles(files: Array<File>, onUpdate : any) : Promise<any> {
    // Create a root reference
    return new Promise((resolve, reject) => {
      const uploadTasks : any = {}
      const finishedUploads : any = {}
      files.forEach((file) => {
        const storageRef = ref(this.storage, `uploads/${file.name}`);
        // 'file' comes from the Blob or File API
        const uploadTask = uploadBytesResumable(storageRef, file)
      
        uploadTask.on('state_changed', (snapshot: any) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadTasks[file.name].progress = progress
          uploadTasks[file.name].state = snapshot.state
          onUpdate({
            uploadTasks,
            finishedUploads
          })
        }, (error) => { uploadTasks[file.name].error = error.code }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              finishedUploads[file.name] = {
                name: file.name,
                path: uploadTask.snapshot.ref.fullPath,
                url: downloadURL
              }
              delete uploadTasks[file.name]
              onUpdate({
                uploadTasks,
                finishedUploads
              })
              if (Object.values(uploadTasks).length < 1) {
                resolve({
                  uploadTasks,
                  finishedUploads
                })
              }
            })
        })
        uploadTasks[file.name] = uploadTask
      })
    })
  }
 
  async submitAdvanceInqueryFunding(advanceInqueryId: string, value: Record<string, any>) : Promise<any> {
    const contractLengthInYears = value.contractLengthInYears
    return await this.callCloudFunction(
      'submitAdvanceInqueryFunding',
      {  
        advanceInqueryId,
        contractLengthInYears
      }
    )
  }

  async getCoupon(couponCode: string, productId: string): Promise<GetCouponResponseBody> {
    return await this.callCloudFunction(
      "getCoupon",
      {
        couponCode,
        productId
      }
    ) as GetCouponResponseBody
  }

  async loginWithProvider(providerId: string) : Promise<any> {
    if (providerId === 'google') {
      const result : any = await signInWithPopup(this.auth, this.googleAuth)
      this.user = {
        id: result.user.uid,
        slug: result.user.email!,
        email: result.user.email!,
        username: result.user.email!
      }
      this.emit('authStateChanged', this.user)
      return this.user
    } else {
      throw "Invalid providerId"
    }
  }

  async login(username: string, password: string) : Promise<any> {
    const result : any = await signInWithEmailAndPassword(this.auth, username, password)
    this.user = {
      id: result.user.uid,
      slug: result.user.email!,
      email: result.user.email!,
      username: result.user.email!
    }
    this.emit('authStateChanged', this.user)
    return this.user
  }
}
