// @ts-nocheck

import React, { ReactElement } from 'react';
import cx from 'classnames';
import Link from '@Components/ui/Link';
import { UiHeading01, UiHeading02 } from '@Components/Text';
import { ILink } from '@Src/types/ContentfulTypes';
import Loader from '@UI/Loader';
import styles from './index.module.scss';
import { SkeletonElement } from '../Skeleton';
export interface Props {
	/**
	 * Link object
	 */
	link?: ILink;
	/**
	 * If Button inverted colors
	 */
	inverted?: boolean;

	/**
	 * IF is black confirm button
	 */
	isConfirm?: boolean;

	/**
	 * If the button is loading
	 */
	isLoading?: boolean;

  isSubmitting?: boolean;

	/**
	 * If the button is disabled
	 */
	disabled?: boolean;

	/**
	 * If using SmallHeader01 or SmallHeader02
	 */
	size?: 'default' | 'small';
	/**
	 * Callback when clicking on button i.e when not usin an link
	 */
	onClick?: () => void;
	/**
	 * Theme
	 */
	theme?: string;
	/**
	 * Merged className
	 */
	className?: string;
	/**
	 * If no link are provided, fallback are button. Specify type of button
	 */
	buttonType?: 'button' | 'submit' | 'reset' | undefined;
}

const BaseButton = ({
	link,
	onClick,
	inverted = false,
	disabled = false,
	size = 'default',
	theme,
	isConfirm = false,
	isLoading = false,
  isSubmitting = false,
	className = '',
	buttonType
}: Props): ReactElement => {
	let labelTheme = theme;
	let isPrimary = link?.primary;
	/* if this is a primary button, ignore theme settings, except if theme is red, then use dark theme to force creme color */
	if (link?.primary) {
		isPrimary = theme === 'red' ? false : link?.primary;
		labelTheme = theme === 'red' ? 'dark' : undefined;
	}

	let dom: ReactElement;
	if (link?.url && link?.target !== '_modal') {
		dom = (
			<Link
				className={cx(
					styles.buttonRoot,
					className,
					isPrimary && styles.primary,
					theme && styles[theme],
					isSubmitting && styles.loading
				)}
				id={link?.id}
				url={link?.url}
				target={link?.target}
			>
      <div style={{flex: 1}} />
        {isLoading ?
          <SkeletonElement style={{width: '100%', height: 300}} />
        : (
          <>
				{isSubmitting && <Loader margin={13} />}
				{size === 'default' && (
					<UiHeading01 className={cx(styles.label, styles[size])} theme={labelTheme} text={link?.label} />
				)}
				{size === 'small' && (
					<UiHeading02 className={cx(styles.label, styles[size])} theme={labelTheme} text={link?.label} />
				)}
        <div style={{flex: 1}} />

				{/*link?.target == '_blank' && <img className={styles.arrowIcon} src={svgExternalArrow} alt="ext" />*/}
        </>)}
			</Link>
		);
	} else {
		/* eslint-disable react/button-has-type */
		dom = (
			<button
				type={buttonType}
				disabled={disabled || isLoading}
        style={{
          opacity: disabled ? 0.5 : 1
        }}
				className={cx(
					styles.buttonRoot,
					buttonType && styles[buttonType],
					className,
					inverted && styles.inverted,
					isPrimary && styles.primary,
					isSubmitting && styles.loading,
					isConfirm && styles.confirm,
					labelTheme && styles[labelTheme]
				)}
				onClick={() => {
					if (onClick) onClick();
				}}
			>
        <div style={{flex: 1}} />
        {isLoading ? 
          <SkeletonElement style={{width: '100%', height: 50}} />
        
				: (
          <>{isSubmitting && <Loader margin={13} />}
				{size === 'default' && (
					<UiHeading01 theme={labelTheme} className={cx(styles.label, styles[size])} text={link?.label} />
				)}
				{size === 'small' && (
					<UiHeading02 theme={labelTheme} className={cx(styles.label, styles[size])} text={link?.label} />
				)}
        <div style={{flex: 1}} />
        </>)}
			</button>
		);
	}

	return dom;
};

export default BaseButton;
