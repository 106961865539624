// @ts-nocheck

import React from 'react';
import cx from 'classnames';

import BaseButton from '../BaseButton';
import styles from 
 './index.module.scss';

interface Props {
  rounded?: Boolean
  white?: boolean
  disabled?: boolean
  primary?: boolean
  isSubmitting: boolean
  theme: string
  label?: string | undefined
  isLoading: boolean
  isSuccess: boolean
  
  onClick: () => void | undefined
  isConfirm: boolean
  buttonType: "submit" | "button" | "reset" | undefined
}

export function SubmitButton ({
  isSubmitting = false,
  isConfirm,
  theme = 'light',
  label = '',
  disabled = false,
  rounded = false,
  white = false,
  isLoading = false,
  primary = false,
  isSuccess = false,
  onClick,
  buttonType = 'submit'
}: Props) {
  return (
    <BaseButton
      /* @ts-ignore */
      className={cx(styles.submitButton, (white && styles.submitButtonWhite
        ), (primary && styles.submitButtonPrimary), (rounded && styles.submitButtonRounded), (theme === 'night' || theme === 'dark') && styles.submitButtonNight)} 
      isSubmitting={isSubmitting || isLoading}
      isConfirm={isConfirm}
      buttonType={buttonType}
      disabled={disabled}
      theme={theme}
      onClick={onClick}
      link={{
        url: '',
        id: 'submit',
        target: '_modal',
        label,
        primary: true
      }}
    />
  )
}