// @ts-nocheck

import React, { useEffect, useState } from 'react';
import cx from 'classnames'
import styles from './index.module.scss';
import Text from '@Src/components/Text';
import i18n from '@Src/i18n';
import { navigate } from '@reach/router'
import Checkbox from '@Src/components/ui/Checkbox';
import { SubmitButton } from '@Src/components/ui/SubmitButton';
import { fetchAdvanceInquery, submitAdvanceInqueryForReview } from '@Src/actions/aloaded';
import Dropzoned from '@Src/components/Dropzoned';

interface Props {
  locale: string
  advanceInqueryId: string
}

const AdvanceInqueryStatementsView = ({
  locale = 'en-US',
  advanceInqueryId
}: Props) => {
  const language = locale.split(/-/g)[0]
  const [submit, setSubmit] = useState<boolean>(false)
  const [files, setFiles] = useState<Array<any>>([])
  const [advanceInquery, setAdvanceInquery] = useState(null)
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false)
  const [ableToMoveCatalogue, setAbleToMoveCatalogue] = useState<boolean>(false)
  const [distributorName, setDistributorName] = useState<string>('');
  const [interestedInBuyout, setInterestedInBuyout] = useState<boolean>(false)
  useState(() => {
    fetchAdvanceInquery(advanceInqueryId).then(advanceInqueryResult => {
      setAdvanceInquery(advanceInqueryResult)
      if (advanceInqueryResult.files) {
        setFiles(advanceInqueryResult.files)
      }
      setAbleToMoveCatalogue(advanceInqueryResult.ableToMoveCatalogue)
      //setInterestedInBuyout(advanceInqueryResult.interestedInBuyout)
      setDistributorName(advanceInqueryResult.distributorName ?? '')
      setAcceptedTermsAndConditions(advanceInqueryResult.acceptedTermsAndConditions)
    })
  })
  useEffect(() => {
    if (files?.length > 0 || ableToMoveCatalogue) {
      setAcceptedTermsAndConditions(true)
    }
  }, [ableToMoveCatalogue, files])
  const handleSubmit = (event : any) => {
    event.preventDefault()
    setSubmit(true)
    if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
      window.dataLayer.push({
        type: 'event',
        event: 'step_4_submit_advance_inquery_for_review'
      })
    }
    submitAdvanceInqueryForReview(
      advanceInqueryId,
      {
        files,
        distributorName,
        ableToMoveCatalogue,
//        interestedInBuyout,
        acceptedTermsAndConditions
      }
    ).then(result => {
      navigate(`/inquery/${advanceInqueryId}/submitted`)
    });
    return false
  }
  const mode = 'light'
  const tosTableCssClasses = ['']
  const onBuyoutTextClick = () => {

  }
  return (
    <form method="POST" className={styles.advanceInqueryStatementsView} style={{opacity: submit ? 0.3 : 1, height: '100%', padding: 0, margin: 0}} onSubmit={handleSubmit}>
      <div style={{padding: 20, flex: 1}}>
        <Text theme={mode} htmlText={i18n.trans(language, 'distribution-reports')} />
        <h3>{i18n.trans(language, 'upload-reports')}</h3>
        <div style={{height: 30}} />  
        {/*<Input
          label={i18n.trans(language, 'enter-your-distributor')}
          id="distributorName"
          required={true}
          onUpdate={(value) => setDistributorName(value)}
          inputType="text" 
        />*/}
        <Text theme={mode} htmlText={i18n.trans(language, 'upload-your-distribution-reports-for-quicker-handling')} />
        <Dropzoned
          defaultUploadedFiles={files}
          language={language}
          onFilesUploaded={(files) => {
            setFiles(files)
          }}
          accept={{
            'application/pdf': ['.pdf'],
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            '	application/vnd.ms-excel': ['.xls']
          }}
        />
        <table className={cx(tosTableCssClasses)}>
          <tbody>
            <tr>
              <td style={{verticalAlign: 'center'}}><br /><br />
                <label className={styles.checkBox}>
                  <Checkbox defaultValue={ableToMoveCatalogue} theme={mode} onUpdate={(value) => setAbleToMoveCatalogue(value)} />
                </label>
              </td>
              <td>
                <Text
                  theme={mode}
                  htmlText={i18n.trans(language, 'i-can-move-catalogue-for-bigger-advance')}
                /> 
              </td>
            </tr>
            {/*<tr>
              <td style={{verticalAlign: 'center'}}><br /><br />
                <label className={styles.checkBox}>
                  <Checkbox defaultValue={interestedInBuyout} theme={mode} onUpdate={(value) => setInterestedInBuyout(value)} />
                </label>
              </td>
              <td>
                <Text theme={mode} htmlText={i18n.trans(language, 'i-am-also-interested-in-suggestions-for-full-catalogue-buy-out')} />
              </td>
            </tr>*/}
          </tbody>
        </table>
        <div className={styles.advanceStatementsViewGutter} />
      </div>
      <div style={{flex: '0 0 auto', padding: 20}} className={styles.advanceStatementsViewFooter}>
        <div style={{flex: 1}} className={styles.advanceStatementsViewFooterTitle}>
            {/*<table>
            <tbody>
              <tr>
                <td style={{padding: 20, verticalAlign: 'center'}}><br /><br />
                label htmlFor="acceptTermsAndConditions" className={styles.checkBox}>
                    <Checkbox theme={mode} onUpdate={(value) => setAcceptedTermsAndConditions(value)} />
                  </label>
                </td>
                <td>
                  <Text
                    theme={mode}
                    htmlText={i18n.trans(language, 'confirm-advance-inquery-submission')}
                  /> 
                </td>
              </tr>
            </tbody>
          </table>*/}
        </div>
        <div style={{textAlign: 'center'}}>
          <SubmitButton
            rounded={true}
            disabled={!acceptedTermsAndConditions && !submit}
            primary={true}
            isSubmitting={submit}
            label={i18n.trans(language, submit ? '' : 'submit-reports')}
          />
        </div>
      </div>  
    </form>
  )
}

export default AdvanceInqueryStatementsView