// @ts-nocheck

import React from 'react';
import styles from 
 './index.module.scss';

interface SkeletonElementProps extends React.ComponentProps<any> {
  children?: any|undefined
}

export function SkeletonElement({
  children,
  ...props
} : SkeletonElementProps) {
  return (
    <div className={styles.skeletonElement} {...props}>{children}</div>
  )
}