// @ts-nocheck

import Text from '@Src/components/Text';
import React, { useEffect } from 'react';
import Link from '../../Link';

import cx from 'classnames';

import styles from './index.module.scss';

interface ButtonGroupButtonProps {
    children: any
    url: string
    theme: string
    active: boolean
}

export const ButtonGroupButton = ({
    children,
    theme,
    url,
    active = false,
    onClick,
    ...props
}: ButtonGroupButtonProps) => {
    const handleClick = (e: any) => {
      e.preventDefault()
      onClick(e)
      return false
    }
    return (

      <button onClick={handleClick} className={cx(styles.buttonGroupButton, theme === 'dark' || theme === 'night' && styles.buttonGroupButtonDark, active && styles.buttonGroupButtonActive, (theme === 'dark' || theme === 'night') && active && styles.buttonGroupButtonDark)} {...props}>
        <Text htmlText={children} />           
      </button>
    )
}