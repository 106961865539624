import { FirebaseAloadedService, firebaseConfig } from "../services/FirebaseAloadedService";
import {AloadedService} from "../services/service";
import { MoodlerAloadedService } from "../services/MoodlerAloadedService";


export const masterService : AloadedService = new FirebaseAloadedService(firebaseConfig, "master")


export const moodlerService : MoodlerAloadedService = new MoodlerAloadedService()

export async function fetchAdvanceInquery(advanceInqueryId: string) : Promise<any> {
  return await moodlerService.fetchAdvanceInquery(advanceInqueryId) as any
}

export async function submitAdvanceInqueryRights(advanceEstimateId: string, rightsValues: Record<string, number>) {
  return await moodlerService.submitAdvanceInqueryRights(
    advanceEstimateId,
    rightsValues
  )
}

export async function uploadFiles(files: Array<File>, onUpdate: (t: any) => void): Promise<any> {
  return await masterService.uploadFiles(files, onUpdate)
}

export async function calculateAdvanceInqueryFunding(advanceInqueryId: string, data: { contractLengthInYears: number, interestedInBuyout: boolean}) {
  return await moodlerService.calculateAdvanceInqueryFunding(
    advanceInqueryId,
    data
  )
}

export async function submitAdvanceInqueryFunding(advanceEstimateId: string, value: { interestedInFullBuyout?: boolean, contractLengthInYears: number}) {
  return await moodlerService.submitAdvanceInqueryFunding(
    advanceEstimateId,
    value
  )
}

export async function submitAdvanceInquery(advanceInquery: any) : Promise<any> {
  return await moodlerService.submitAdvanceInquery(advanceInquery)
}

export async function findArtistsByName(q: string): Promise<any> {
  return await moodlerService.findArtistsByName(q)
}

export async function submitAdvanceInqueryForReview(advanceInqueryId : string, data : any) {
  return moodlerService.submitAdvanceInqueryForReview(
    advanceInqueryId,
    data
  )
}